define('m0601/models/einwirkung', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	var einwirkung = DS['default'].Model.extend({

		typ1: DS['default'].attr('string'),
		typ2: DS['default'].attr('string'),
		typ3: DS['default'].attr('string'),
		typ4: DS['default'].attr('string'),
		typ5: DS['default'].attr('string'),
		typ6: DS['default'].attr('string'),
		typ7: DS['default'].attr('string'),
		typ8: DS['default'].attr('string'),
		typ9: DS['default'].attr('string'),
		typ10: DS['default'].attr('string'),
		kled1: DS['default'].attr('string'),
		kled2: DS['default'].attr('string'),
		kled3: DS['default'].attr('string'),
		kled4: DS['default'].attr('string'),
		kled5: DS['default'].attr('string'),
		kled6: DS['default'].attr('string'),
		kled7: DS['default'].attr('string'),
		kled8: DS['default'].attr('string'),
		kled9: DS['default'].attr('string'),
		kled10: DS['default'].attr('string'),
		eigengewichtBerechnen: DS['default'].attr('boolean'),

		einwirkungen: DS['default'].hasMany('einzelneeinwirkung', { async: false })

	});

	einwirkung.reopenClass({
		FIXTURES: [{
			id: 1,
			typ1: 1,
			typ2: 1,
			typ3: 1,
			typ4: 1,
			typ5: 1,
			typ6: 1,
			typ7: 1,
			typ8: 1,
			typ9: 1,
			typ10: 1,
			kled1: 1,
			kled2: 1,
			kled3: 1,
			kled4: 1,
			kled5: 1,
			kled6: 1,
			kled7: 1,
			kled8: 1,
			kled9: 1,
			kled10: 1,
			eigengewichtBerechnen: false
		}]
	});

	exports['default'] = einwirkung;

});